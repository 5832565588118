<template>
  <div class="w-full">
    <TotalComplaints
      :total="count"
      title="Total de grupos"
      body="grupos con duplicados"
    />
    <DuplicateComplaintsFilter
      @fetch-complaints="setQueries($event)"
      :query="start_query"
      duplicate="true"
      :loading="loading"
    />
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
    <div
      v-if="!loading && complaints"
      class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4 mt-4 duplicate-complaints"
    >
      <a-table
        :columns="columns"
        rowKey="idx"
        :data-source="complaints"
        :pagination="false"
      >
        <div
          slot="entity_name"
          class="title-size font-bold text-gray-500 text-center"
        >
          Entidad
        </div>
        <div
          slot="consumer_name"
          class="title-size font-bold text-gray-500 text-center"
        >
          Nombre del consumidor
        </div>
        <div
          slot="document_type"
          class="title-size font-bold text-gray-500 text-center"
        >
          Tipo de documento
        </div>
        <div
          slot="document_number"
          class="title-size font-bold text-gray-500 text-center"
        >
          No. de documento
        </div>
        <div
          slot="total_complaints"
          class="title-size font-bold text-gray-500 text-center"
        >
          No. de quejas
        </div>

        <div
          slot="actions_management"
          class="title-size font-bold text-gray-500 relative right-8"
        >
          Acciones
        </div>

        <div slot="consumer_name" slot-scope="text, record" class="text-center">
          {{ record.consumer_name }}
        </div>
        <div slot="document_type" slot-scope="text, record" class="text-center">
          {{ record.document_type }}
        </div>
        <div
          slot="document_number"
          slot-scope="text, record"
          class="text-center"
        >
          {{ record.document_number }}
        </div>
        <div
          slot="total_complaints"
          slot-scope="text, record"
          class="text-center font-bold"
        >
          {{ record.total_complaints }}
        </div>
        <div slot="actions_watch" slot-scope="text, record">
          <button
            class="flex justify-center col-span-1 text-xs pr-2 text-blue h-min m-auto"
            @click="goToViewComplaints(text, record)"
          >
            <a-icon
              type="eye"
              theme="twoTone"
              two-tone-color="#3366cc"
              :style="{
                fontSize: '20px',
              }"
            />
            <b class="my-auto ml-2">Ver</b>
          </button>
        </div>
        <div
          slot="actions_management"
          slot-scope="text, record"
          class="text-right"
        >
          <BaseButton
            :disable="record.gestionada"
            @click.native="onManagementComplaints(text, record)"
            :label="record.gestionada ? 'Gestionada' : 'Gestionar'"
          />
        </div>
        <div
          slot="actions_release"
          slot-scope="text, record"
          class="text-right"
        >
          <BaseButton
            @click.native="onReleaseComplaints(text, record)"
            :label="record.gestionada ? 'Gestionada' : 'Liberar'"
            class="bg-orange border-orange"
            :disable="record.gestionada"
          />
        </div>
      </a-table>
      <div class="p-4 flex justify-end">
        <a-pagination
          v-model="page"
          :page-size="pageSize"
          :page-size-options="pageSizeOptions"
          :total="count"
          show-size-changer
          size="large"
          @showSizeChange="onShowSizeChange"
        />
      </div>
    </div>
    <div v-if="loading" class="absolute w-full justify-center mt-7">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <!-- #region Modal para gestionar quejas duplicadas -->
    <Modal id="managementModal" v-if="showManagementModal">
      <h1 class="text-blue-800 text-3xl font-bold my-3">
        Gestionar queja duplicada
      </h1>
      <p>Gestiona y administra las quejas duplicadas en un solo radicado.</p>
      <div class="w-full flex flex-col">
        <div class="w-3/5 my-6 mx-auto">
          <BaseSelect
            id="complaintsManagement"
            ref="complaintsManagement"
            label="Id del radicado en el que se unificarán las quejas"
            :selectOptions="complaints_management"
            v-model="complaint_management"
            :loading="loadingModals"
          />
        </div>
        <div class="flex gap-3 justify-center my-3x">
          <BaseButton
            label="UNIFICAR QUEJAS"
            @click.native="onConfirmationUnifyComplaints"
          />
          <BaseButton
            label="CANCELAR"
            danger="true"
            @click.native="closeModal"
          />
        </div>
      </div>
      <p class="text-red-500 mt-3" v-if="modalFeedback">
        * {{ modalFeedback }}
      </p>
    </Modal>
    <!-- #endregion -->
    <!-- #region Modal de confirmacion para unificar -->
    <Modal id="confirmationModal" v-if="showConfirmationUnifyModal">
      <h1 class="text-blue-800 text-3xl font-bold my-3">
        Unificar quejas duplicadas
      </h1>
      <p class="my-8">
        ¿Esta seguro que deseas unificar las
        <b>{{ this.complaints_management.length }} </b>quejas seleccionadas en
        el
        <b>
          Id. radicado número
          {{ this.complaints_management[this.complaint_management].name }}</b
        >?
      </p>
      <div class="flex gap-3 justify-center my-3x capitalize">
        <BaseButton label="ACEPTAR" @click.native="onUnifyComplaints" />
        <BaseButton
          label="CANCELAR"
          danger="true"
          @click.native="closeModal"
        /></div
    ></Modal>
    <!-- #endregion -->
    <!-- #region Modal para liberar quejas duplicadas -->
    <Modal id="releaseModal" v-if="showReleaseModal">
      <h1 class="text-blue-800 text-3xl font-bold my-3">
        Selecionar Queja(s) a Liberar
      </h1>
      <p>Escoge 1 o múltiples quejas que deseas liberar.</p>
      <div class="w-full flex flex-col">
        <div class="w-3/5 my-6 mx-auto">
          <BaseMultiSelect
            title="Id del radicado de quejas que desea liberar"
            :data="complaints_management"
            label="name"
            @notify-select="complaintsToRelease = $event"
            v-if="!loadingModals && complaints_management.length > 0"
          />
          <div v-else class="spinner">
            <a-spin>
              <a-icon
                slot="indicator"
                type="loading"
                style="font-size: 27px"
                spin
              />
            </a-spin>
          </div>
        </div>
        <div class="flex gap-3 justify-center my-3x capitalize">
          <BaseButton
            label="LIBERAR QUEJAS"
            @click.native="onReleaseComplaintsConfirmation"
          />
          <BaseButton
            label="CANCELAR"
            danger="true"
            @click.native="closeModal"
          />
        </div>
      </div>
      <p class="text-red-500 mt-3" v-if="modalFeedback">
        * {{ modalFeedback }}
      </p></Modal
    >
    <!-- #endregion -->
    <!-- #region Modal de confirmacion para liberar -->
    <Modal id="confirmationModal" v-if="showConfirmationReleaseModal">
      <h1 class="text-blue-800 text-3xl font-bold my-3">
        Liberar grupo de quejas
      </h1>
      <div class="my-8">
        <p>
          ¿Esta seguro que deseas unificar las quejas seleccionadas con los
          siguientes números de radicado:
          <br /><br />
        </p>

        <div v-for="complaint in complaintsToRelease" :key="complaint">
          <b> Id. radicado número {{ complaint }}</b
          ><br />
        </div>
        <p>?</p>
      </div>

      <div class="flex gap-3 justify-center my-3x capitalize">
        <BaseButton label="ACEPTAR" @click.native="releaseComplaints" />
        <BaseButton label="CANCELAR" danger="true" @click.native="closeModal" />
      </div>
    </Modal>
    <!-- #endregion -->
  </div>
</template>

<script>
import DuplicateComplaintsFilter from "./DuplicateComplaintsFilter.vue";
import TotalComplaints from "../Complaints/TotalComplaints.vue";
import capitalize from "@/utils/checkForms.js";
import ComplaintDetailsVue from "../../../views/dashboard/complaints/ComplaintDetails.vue";
const columns = [
  {
    dataIndex: "entity_name",
    key: "entity_name",
    slots: { title: "entity_name" },
  },
  {
    slots: { title: "consumer_name" },
    dataIndex: "consumer_name",
    key: "consumer_name",
    scopedSlots: { customRender: "consumer_name" },
  },
  {
    slots: { title: "document_type" },
    dataIndex: "document_type",
    key: "document_type",
    scopedSlots: { customRender: "document_type" },
  },
  {
    slots: { title: "document_number" },
    dataIndex: "document_number",
    key: "document_number",
    scopedSlots: { customRender: "document_number" },
  },
  {
    dataIndex: "total_complaints",
    key: "total_complaints",
    scopedSlots: { customRender: "total_complaints" },
    slots: { title: "total_complaints" },
  },

  {
    title: "",
    dataIndex: "actions_watch",
    key: "actions_watch",
    scopedSlots: { customRender: "actions_watch" },
  },
  {
    slots: { title: "actions_management" },
    dataIndex: "actions_management",
    key: "actions_management",
    scopedSlots: { customRender: "actions_management" },
    colSpan: 3,
  },
  {
    title: "",
    dataIndex: "actions_release",
    key: "actions_release",
    scopedSlots: { customRender: "actions_release" },
  },
];
export default {
  components: { DuplicateComplaintsFilter, TotalComplaints },
  data() {
    return {
      columns,
      form: {},
      legend: "",
      count: 0,
      complaints: [],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      page: 1,
      showManagementModal: false,
      showReleaseModal: false,
      showConfirmationUnifyModal: false,
      showConfirmationReleaseModal: false,
      complaint_management: {},
      complaints_management: [],
      complaintsToRelease: [],
      pageSize: 50,
      totalCriteria: 0,
      start_query: {},
      pageSizeOptions: ["50", "100", "150", "200", "250"],
      loadingModals: false,
      modalFeedback: "",
    };
  },
  created() {
    this.start_query = this.$route.query;
    this.form = this.start_query;

    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    }

    this.fetchComplaints(this.buildQuery());
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        let query = this.form;
        query.page = this.page;
        this.$router.replace({ query }).catch(() => {
          return;
        });
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router
          .replace({
            query: { page: this.page, pageSize: this.pageSize },
          })
          .catch(() => {
            return;
          });
      }
    },
    "$route.query.page": function () {
      this.fetchComplaints(this.buildQuery());
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
  },
  methods: {
    goToViewComplaints(checked, user) {
      this.$router.push({ name: "ComplaintsDetailView", params: { user } });
    },
    onManagementComplaints(checked, userBasicInfo) {
      this.complaint_management = "";
      this.complaints_management = [];
      this.showManagementModal = true;
      this.getDuplicateComplaintsInfo(userBasicInfo);
    },
    async getDuplicateComplaintsInfo(userBasicInfo) {
      this.loadingModals = true;
      let query = `${userBasicInfo.group_id}/?tipo_identificacion_CF=${userBasicInfo.document_type}&nombre_entidad=${userBasicInfo.entity_name}&numero_id_CF=${userBasicInfo.document_number}`;
      let { data, error } = await this.$api.getDuplicateComplaintsByUser(query);
      if (data) {
        this.complaints_management = data.map((complaint_code, id) => {
          return { name: complaint_code.codigo_queja, id };
        });
        this.loadingModals = false;
      } else if (error) {
        this.showManagementModal = false;
        this.showReleaseModal = false;
        this.showToast(
          "error",
          "Ocurrío un error al obtener las quejas duplicadas. Intenete nuevamente",
          null
        );
        this.loadingModals = false;
      }
    },
    onConfirmationUnifyComplaints() {
      if (this.complaint_management) {
        this.showManagementModal = false;
        this.showConfirmationUnifyModal = true;
        this.modalFeedback = "";
      } else this.modalFeedback = "Seleccione una queja.";
    },
    async onUnifyComplaints() {
      this.loading = true;
      this.modalFeedback = "";
      this.showConfirmationUnifyModal = false;
      const idPrincipal =
        this.complaints_management[this.complaint_management].name;

      this.complaints_management.splice(this.complaint_management, 1);
      let { error, data } = await this.$api.managementDuplicateComplaints(
        idPrincipal,
        {
          complaints: this.complaints_management.map((complaint) => {
            return complaint.name;
          }),
        }
      );
      if (data) {
        this.fetchComplaints(this.buildQuery());
        this.showToast(
          "success",
          "Quejas unificadas de manera exitosa.",
          "Se han guardado las modificaciones generadas en el grupo de quejas duplicadas.",
          true
        );
      } else if (error) {
        this.showConfirmationUnifyModal = false;
        this.loading = false;
        this.showToast(
          "error",
          "Ocurrío un error al unificar las quejas duplicadas. Intenete nuevamente",
          null
        );
      }
    },

    onReleaseComplaints(checked, userBasicInfo) {
      this.showReleaseModal = true;
      this.complaints_management = [];
      this.getDuplicateComplaintsInfo(userBasicInfo);
    },
    onReleaseComplaintsConfirmation() {
      if (this.complaintsToRelease?.length > 0) {
        this.showReleaseModal = false;
        this.showConfirmationReleaseModal = true;
        this.modalFeedback = "";
      } else this.modalFeedback = "Seleccione las quejas a liberar";
    },
    async releaseComplaints() {
      this.loading = true;
      this.modalFeedback = "";
      this.showConfirmationReleaseModal = false;
      let { error, data } = await this.$api.releaseDuplicateComplaints({
        complaints: this.complaintsToRelease,
      });
      if (data) {
        this.fetchComplaints(this.buildQuery());
        this.showToast(
          "success",
          "Quejas liberadas de manera exitosa.",
          "Se han guardado las modificaciones generadas en el grupo de quejas duplicadas.",
          true
        );
      } else if (error) {
        this.showConfirmationUnifyModal = false;
        this.loading = false;
        this.showToast(
          "error",
          "Ocurrío un error al liberar las quejas duplicadas. Intenete nuevamente",
          null
        );
      }
    },

    closeModal() {
      this.showConfirmationUnifyModal = false;
      this.showManagementModal = false;
      this.showReleaseModal = false;
      this.showConfirmationReleaseModal = false;
      this.modalFeedback = "";
    },

    setQueries(myForm) {
      this.form = myForm;
      this.showToast("info", "Buscando resultados...");
      if (1 == this.$route.query.page || !this.$route.query.page) {
        let query = this.form;
        query.page = this.page;

        this.$router.replace({ query }).catch(() => {
          return;
        });

        this.fetchComplaints(this.buildQuery());
      }
      this.page = 1;
    },
    async fetchComplaints(query) {
      this.loading = true;
      let { error, data } = await this.$api.getDuplicateComplaints(query);
      this.loading = false;

      if (error) {
        this.legend = "Ocurrió un error al realizar la petición.";
        this.complaints = null;
        this.count = 0;
        this.showToast("error", "Error", "Ocurrió un error con la consulta");
      }
      if (data) {
        this.count = data.count;
        if (this.count != 0) {
          this.legend = "";
          this.complaints = data.results.map((complaint, idx) => {
            return {
              idx,
              entity_name: capitalize(complaint.nombre_entidad),
              consumer_name: capitalize(complaint.nombres),
              document_type: complaint.tipo_identificacion_CF,
              document_number: complaint.numero_id_CF,
              total_complaints: complaint.total,
              group_id: complaint.agrupador,
              gestionada: complaint.gestionada == 1 ? true : false,
            };
          });
        } else {
          this.legend = "No se encontraron resultados";
          this.complaints = null;
          this.count = 0;
        }
      }
    },

    buildQuery() {
      let query = `?page_size=${this.pageSize}&page=${this.page}&`;

      if (this.form.nombre_entidad)
        query += `nombre_entidad=${this.form.nombre_entidad}&`;

      if (this.form.codigo_queja)
        query += `codigo_queja=${this.form.codigo_queja}&`;

      if (this.form.tipo_identificacion_CF)
        query += `tipo_identificacion_CF=${this.form.tipo_identificacion_CF}&`;

      if (this.form.numero_id_CF)
        query += `numero_id_CF=${this.form.numero_id_CF}&`;

      if (this.form.estado) query += `estado=${this.form.estado}&`;

      if (this.form.estado_duplicidad_procesado)
        query += `estado_duplicidad_procesado=${this.form.estado_duplicidad_procesado}&`;

      return query;
    },

    onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      //this.fetchComplaints("?" + window.location.href.split("?")[1]);
    },
    showToast(result = "info", title = "Resultado", msg, btn) {
      const key = `open${Date.now()}`;
      this.$notification[result]({
        message: title,
        description: msg,
        btn: btn
          ? (h) => {
              return h(
                "a-button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => this.$notification.close(key),
                  },
                },
                "Entendido"
              );
            }
          : null,
        key,
        onClose: close,
        style: {
          width: "500px",
          marginLeft: `${350 - 500}px`,
        },
      });
    },
  },
};
</script>
<style>
.duplicate-complaints .ant-table-thead > tr > th {
  text-align: center;
}
</style>