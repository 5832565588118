<template>
  <DuplicateManagement />
</template>

<script>
import DuplicateManagement from "@/components/Dashboard/DuplicateComplaints/DuplicateManagement.vue";
export default {
  components: { DuplicateManagement },
  data() {
    return {};
  },
};
</script>
